import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"
// import Layout from "@/components/layout"
// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"
import Parallax from "@/components/general/Parallax"
import Heading from "@/components/general/Heading"
import RentsGraph from "@/components/graphs/RentsGraph.es"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup.es"
import Dropcap from "@/components/general/Dropcap"
import Share from "@/components/general/Share.es"
import FooterNav from "@/components/general/FooterNav"
import Motif from "@/components/general/Motif"
import Footer from "@/components/general/Footer.es"
import pullquote from "@/images/rents/pullquote_spanish.svg"
import pullquote2 from "@/images/rents/pullquote2_spanish.svg"

import openerText from "@/images/rents/opener-text_spanish.png"
import openerTextSml from "@/images/rents/opener-text-sml_spanish.png"

import dci from "@/images/rents/dci_spanish.png"
import shareImg from "@/images/og/og_rents_spanish.jpg"
import SignupPopup from "@/components/general/SignupPopup.es"

const RentsPage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/es/high-rents"
                title="Free Markets Destroy | Aprenda cómo los mercados libres destruyen los alquileres altos"
                description="En América y Europa, los precios de las viviendas se han disparado durante tres décadas. Mientras tanto, los ingresos se han estancado. Pero los precios de alquiler no están aumentando en todas partes."
                img={shareImg}
                lang="es"
            />

            <Opener
                pageName="rents"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            ></Opener>

            {
                // ======================================= SECTION 1 =======================================
            }

            <Sizing
                styles={{ float: "left", position: "relative", zIndex: 4 }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 20,
                            marginBottom: 1,
                            // marginTop: 2,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 2,
                            width: 10,
                            marginBottom: 1,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <div className="copy--lead" style={{ position: "relative" }}>
                    <Dropcap img={dci}>E</Dropcap>n 2018, la Coalición Nacional de Viviendas de Bajos Ingresos anunció que un inquilino que gana el salario mínimo en un trabajo de tiempo completo podría permitirse un apartamento típico de dos dormitorios en exactamente cero condados de los Estados Unidos. Cero. El mismo trabajador de salario mínimo a tiempo completo podría permitirse un apartamento de una habitación en solo 22 de más de 3,000 condados en todo el país. Llamar a esto una crisis de adquirimiento de vivienda parece un eufemismo masivo.
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -9,
                                    bottom: -1,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="rents" id="motif_1" movement={-0.1} />
                    </Sizing>
                </div>

                <p className="copy">
                    A través de Estados Unidos y Europa, los precios de las viviendas se han disparado durante tres décadas. Mientras tanto, los ingresos se han estancado. En ciudades como Londres, Boston y Seattle, la relación entre los precios de las viviendas y las ganancias se ha duplicado o incluso triplicado en los últimos 10 años. Y las restricciones dirigidas a limitar el crecimiento y a inyectar dinero público en viviendas subsidiadas en realidad han empeorado el problema, mucho peor—en muchas las ciudades más inalcanzables de Estados Unidos.
                </p>
                <div style={{ position: "relative" }}>
                    <p className="copy">
                        Pero los precios de alquiler no están aumentando en todas partes. En Tokio, los precios de las viviendas se han mantenido relativamente estables durante 25 años. ¿Qué pasa? Con una población estimada de 38 millones, el área metropolitana de Tokio es la región metropolitana más poblada del mundo, más grande que la ciudad de Nueva York y Los Ángeles juntos. Tokio es un centro financiero global, hogar de 36 de las empresas más grandes del mundo, y un importante centro de medios, diseño y fabricación. Entonces, ¿por qué la crisis de accesibilidad no ha golpeado a la capital japonesa?{" "}
                    </p>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -6,
                                    bottom: 0,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="rents" id="motif_2" movement={0.1} />
                    </Sizing>
                </div>
            </Sizing>

            <div style={{ float: "left", clear: "left" }}>
                <SignupPopup></SignupPopup>
            </div>

            <RentsGraph></RentsGraph>

            <ColorChangeBG
                from={{ r: 54, g: 153, b: 221 }}
                to={{ r: 104, g: 197, b: 184 }}
            >
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                                // marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 4,
                                width: 6,
                                marginBottom: 1,
                                marginTop: 2,
                            },
                        },
                    ]}
                >
                    <img
                        style={{
                            width: "100%",
                        }}
                        src={pullquote}
                        alt=""
                    />

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -2,
                                    bottom: -4,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="rents" id="motif_9" movement={-0.2} />
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                                marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 8,
                                marginBottom: 1,
                                marginTop: 2,
                            },
                        },
                    ]}
                >
                    <p className="copy">
                        Una razón importante, según los expertos, son los mercados. Con esencialmente ningún control de alquiler y muy pocas restricciones en altura y densidad, Tokio es la rara ciudad moderna donde la oferta puede responder a la demanda en tiempo real. En otras palabras, es un mercado libre. Los precios suben y bajan a medida que la oferta de viviendas nuevas y disponibles cambia para satisfacer las necesidades de los residentes.
                    </p>
                    <p className="copy">
                        Ajustado para la población, Tokio aprueba dos "inicios de vivienda" —nuevos proyectos de construcción residencial-por cada uno en la ciudad de Nueva York. Es por eso que el alquiler promedio de un apartamento de dos dormitorios en Tokio fue menos de $1,000 por mes en 2018, menos de la mitad del costo del mismo apartamento en Seattle.

                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 1,
                                width: 22,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 1,
                                width: 18,
                                paddingBottom: 0.3,
                            },
                        },
                    ]}
                >
                    <Image style={{ width: "100%" }} src="rents_capsule" />
                </Sizing>

                <Sizing
                    styles={{ float: "right", textAlign: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 1,
                                width: 22,
                                marginTop: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 1,
                                width: 18,
                                marginTop: -0.8,
                            },
                        },
                    ]}
                >
                    <span className="caption">
                        - Torre cápsula Nakagin de Tokio, diseñada por el arquitecto Kisho Kurokawa
                    </span>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 4,
                                width: 18,
                                marginBottom: 1,
                                marginTop: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 2,
                                marginRight: 2,
                                width: 4,
                                minWidth: "350px",
                            },
                        },
                    ]}
                >
                    <Signup color="#EC5843"></Signup>
                </Sizing>

                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                // marginBottom: 1,
                                marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: { marginTop: 2, marginLeft: 3, width: 8 },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -3,
                                    top: -4,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="rents" id="motif_3" movement={0.1} />
                    </Sizing>

                    <p className="copy--lead">
                        Y en San Francisco, donde las estrictas regulaciones de vivienda y los intentos equivocados de control de alquileres han producido los costos de vivienda más altos de cualquier ciudad estadounidense importante: casi 4 4,000 para un apartamento típico de una habitación.
                    </p>

                    <p className="copy">
                        En 2015, después de que la Junta de Supervisores de San Francisco intentara aprobar una "moratoria" bloqueando todo nuevo desarrollo de viviendas en el popular Distrito de la Misión, el supervisor de la Ciudad de San Francisco, Scott Wiener (ahora senador del estado de California) publicó un artículo de opinión titulado "Sí, la oferta y la demanda se Aplican a la Vivienda, incluso en San Francisco.”
                    </p>

                    <p className="copy">
                        Wiener declaró: "Nunca nos hemos acercado a producir suficiente vivienda para permitir que alguien argumente que el aumento de la oferta de vivienda no estabiliza los precios de la vivienda. Pero tenemos pruebas de lo contrario. Desde 2003, San Francisco ha crecido en casi 100,000 personas, mientras que produce alrededor de 24,000 unidades de vivienda. Durante ese mismo período, los precios de la vivienda se han disparado.”
                    </p>

                    <p className="copy">
                        Wiener concluyó: "Los principios económicos no siempre son convenientes, pero son reales. La oferta y la demanda existen, y se aplica en San Francisco.”
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative", zIndex: 2 }}
                    breaks={[
                        {
                            min: 0,
                            val: { display: "none" },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 4,
                                marginRight: 3,
                                width: 7,
                                display: "block",
                            },
                        },
                    ]}
                >
                    <img
                        style={{
                            width: "100%",
                        }}
                        src={pullquote2}
                        alt=""
                    />
                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: { paddingTop: 0.3 },
                            },
                        ]}
                    >
                        <span
                            className={"caption caption--large caption--white"}
                        >
                            El supervisor de la Ciudad, Scott Wiener
                        </span>
                    </Sizing>
                </Sizing>
            </ColorChangeBG>
            <ColorChangeBG
                from={{ r: 104, g: 197, b: 184 }}
                to={{ r: 183, g: 131, b: 185 }}
            >
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: 3,
                                paddingBottom: 0.3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: -1,
                                paddingBottom: 0.3,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: -3,
                                paddingBottom: 0.3,
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -4,
                                    top: 3,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="rents" id="motif_2" movement={-0.15} />
                    </Sizing>
                    <Image
                        style={{ width: "100%" }}
                        src={"rents_tents"}
                    ></Image>
                </Sizing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 3,
                                width: 18,
                                marginBottom: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 5,
                                width: 15,
                                marginBottom: 0,
                            },
                        },
                    ]}
                >
                    <span className="caption">
                        - Un campamento de personas sin hogar en Seattle.
                    </span>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                // marginBottom: 1,
                                marginTop: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: { marginLeft: 4, width: 8, marginTop: 1 },
                        },
                    ]}
                >
                    <Heading langKey={pageContext.langKey}>EL MILAGRO DE H-TOWN</Heading>
                </Sizing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                // marginBottom: 1,
                                marginTop: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: { marginLeft: 5, width: 10, marginTop: 2 },
                        },
                        {
                            min: 1200,
                            val: { marginLeft: 7, width: 8, marginTop: 2 },
                        },
                    ]}
                >
                    <p className="copy--lead">
                        Una ciudad estadounidense que ha logrado mantener los alquileres relativamente bajos, incluso cuando la población local ha explotado, es Houston, Texas.
                    </p>

                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        left: -4.5,
                                        top: 0,
                                        width: 1.2,
                                    },
                                },
                            ]}
                        >
                            <Motif page="rents" id="motif_6" movement={-0.15} />
                        </Sizing>
                        <p className="copy">
                            En la primera década del siglo 21, el área metropolitana de Houston creció en un asombroso 20%, aumentando de 4.7 a 6 millones de residentes, pero durante ese mismo período los alquileres realmente disminuyeron. Entonces, ¿cuál es el secreto de la accesibilidad sustentable de Houston? Desregulación.
                        </p>
                    </div>
                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        right: -7,
                                        top: 0,
                                        width: 1.2,
                                    },
                                },
                            ]}
                        >
                            <Motif page="rents" id="motif_5" movement={-0.15} />
                        </Sizing>

                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        right: -7,
                                        top: 4,
                                        width: 2,
                                    },
                                },
                            ]}
                        >
                            <Motif page="rents" id="motif_8" movement={-0.2} />
                        </Sizing>
                        <p className="copy">
                            Houston es la única ciudad grande en América sin restricciones de zonificación oficiales. Los resultados pueden ser excéntricos: tiendas de videos para adultos ubicadas al lado de oficinas corporativas, iglesias modernas encajadas entre almacenes y edificios residenciales en una amplia variedad de formas, colores y estilos. Pero al final del día, este enfoque de laissez faire para la vivienda ha ayudado a Houston a seguir siendo una de las pocas ciudades estadounidenses donde los Milenios pueden permitirse comprar su “primera casa”.
                        </p>
                    </div>

                    <p className="copy">
                        "El éxito de Houston", escribió el economista de Harvard Edward Glaeser, " muestra que una ciudad de mercado libre relativamente desregulada, con una poderosa máquina de crecimiento urbano, puede hacer un trabajo mucho mejor para cuidar a los estadounidenses de ingresos medios.”
                    </p>

                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        left: -6,
                                        top: 0,
                                        width: 2,
                                    },
                                },
                            ]}
                        >
                            <Motif page="rents" id="motif_8" movement={-0.2} />
                        </Sizing>

                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        right: -2.5,
                                        top: -1,
                                        width: 1,
                                    },
                                },
                            ]}
                        >
                            <Motif page="rents" id="motif_4" movement={0.15} />
                        </Sizing>

                        <p className="copy">
                            En las grandes ciudades, las restricciones y regulaciones anunciadas como" protecciones " tienden a aislar a los vecindarios ricos de los inquilinos de bajos ingresos. Históricamente, estas restricciones eran abiertamente racistas, las maquinaciones de los ayuntamientos y "expertos" que querían excluir a las personas de color y otros grupos marginados. Incluso cuando las regulaciones son bien intencionadas, a menudo tienen el efecto deseado de hacer ilegal la vivienda barata al prohibir edificios altos, apartamentos pequeños y cualquier cosa excéntrica o fea.
                        </p>
                    </div>

                    <p className="copy">
                        El camino hacia una crisis de asequibilidad está pavimentado con regulaciones. Si queremos bajar rentas y crear más oportunidades para todos, tenemos que seguir Tokio y liberar el poder de los mercados.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 0,
                                width: 24,
                                marginBottom: 4,
                                marginTop: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: -2,
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 2,
                            },
                        },
                    ]}
                >
                    <Image src="rents_demo" style={{ width: "100%" }}></Image>
                </Sizing>

                <Spacing space="0" />
                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: 20,
                                maxWidth: "300px",
                                height: "50px",
                                marginTop: 2,
                                paddingBottom: 5,
                                position: "relative",
                                display: "block",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "none",
                            },
                        },
                    ]}
                >
                    <Share></Share>
                </Sizing>
            </ColorChangeBG>
            <FooterNav next={"climate"} previous={"hunger"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default RentsPage
